import ViewInArIcon from "@mui/icons-material/ViewInAr";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React from "react";

interface Props {
  modelUrl: string;
  cameraControls: boolean;
  ar: boolean;
  autoRotate: boolean;
  arButtonTitle?: string;
  arFailureTitle?: string;
  fullScreen: boolean;
  paddingBottom?: number;
}

export function ThreeDViewer({
  modelUrl,
  cameraControls,
  ar,
  autoRotate,
  arButtonTitle = "View in your space",
  arFailureTitle = "AR is not tracking!",
  fullScreen,
  paddingBottom = 0,
}: Props) {
  const scriptLoaded = useModelViewerScript();
  const fileLoaded = useFileLoad(modelUrl);
  return !scriptLoaded || !fileLoaded ? (
    <Box flex={1} display="flex" justifyContent="center" alignItems="center">
      <CircularProgress
        sx={theme => ({
          color:
            theme.palette.mode === "dark"
              ? theme.palette.common.white
              : theme.palette.primary.main,
        })}
        color="inherit"
        size={24}
      />
    </Box>
  ) : (
    <Box
      flex={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
      position="relative"
      sx={{
        "model-viewer .ar-button": {
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          bottom: fullScreen
            ? `calc(env(safe-area-inset-bottom) + 16px + ${paddingBottom}px)`
            : `calc(16px + ${paddingBottom}px)`,
        },
        "model-viewer .ar-failure": {
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          bottom: fullScreen
            ? `calc(env(safe-area-inset-bottom) + 48px + ${paddingBottom}px)`
            : `calc(48px + ${paddingBottom}px)`,
          display: "none",
        },
        'model-viewer[ar-tracking="not-tracking"] .ar-failure': {
          display: "block",
        },
      }}
    >
      {/* @ts-ignore */}
      <model-viewer
        {...(ar ? { ar: true } : undefined)}
        {...(cameraControls ? { "camera-controls": true } : undefined)}
        {...(autoRotate ? { "auto-rotate": true } : undefined)}
        // ar={ar}
        // camera-controls={cameraControls}
        // auto-rotate={autoRotate}
        quick-look-browsers="safari chrome edge firefox"
        camera-orbit="45deg 45deg"
        min-camera-orbit="-Infinity 0deg auto"
        max-camera-orbit="Infinity 93deg auto"
        src={modelUrl}
        shadow-intensity="1"
        alt="3D model viewer"
        touch-action="pan-y"
        style={{
          height: "100%",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Button
          slot="ar-button"
          className="ar-button"
          size="medium"
          variant="contained"
          color="secondary"
          startIcon={<ViewInArIcon />}
        >
          {arButtonTitle}
        </Button>

        <div className="ar-failure">
          <Typography variant="body2" color="error">
            {arFailureTitle}
          </Typography>
        </div>
        {/* @ts-ignore */}
      </model-viewer>
    </Box>
  );
}

let script: HTMLScriptElement | undefined;
let isScriptLoaded = false;

const useModelViewerScript = () => {
  const [loaded, setLoaded] = React.useState<boolean>(isScriptLoaded);

  React.useLayoutEffect(() => {
    if (!script) {
      script = document.createElement("script");
      const head = document.head || document.getElementsByTagName("head")[0];

      script.src =
        "https://ajax.googleapis.com/ajax/libs/model-viewer/3.5.0/model-viewer.min.js";
      script.async = true; // optionally
      script.type = "module";
      script.onload = () => {
        isScriptLoaded = true;
        setLoaded(true);
      };

      head.appendChild(script);
    }
  }, []);

  return loaded;
};

const useFileLoad = (fileUrl: string) => {
  const [loaded, setLoaded] = React.useState<boolean>(false);

  React.useLayoutEffect(() => {
    if (!fileUrl) return;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetch(fileUrl, {
      referrerPolicy: "strict-origin-when-cross-origin",
      body: null,
      method: "GET",
      mode: "cors",
      credentials: "omit",
    }).then(() => {
      setLoaded(true);
    });
  }, [fileUrl]);

  return loaded;
};
