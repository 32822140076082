import MutationQueueLink from "@adobe/apollo-link-mutation-queue";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  TypePolicies,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { RetryLink } from "@apollo/client/link/retry";
import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { assert } from "@msys/common";
import * as Sentry from "@sentry/react";
import { ProviderContext, useSnackbar } from "notistack";
import * as React from "react";
import { createNetworkStatusNotifier } from "react-apollo-network-status";
import { useLatest } from "react-use";
import { AuthContext } from "../app/auth/AuthContext";
import { useAuth } from "../app/auth/useAuth";
import { useSelectedUser } from "../app/auth/useSelectedUser";
import { SELECTED_USER_HTTP_HEADER } from "../app/constants";
import { track } from "../app/track";
import { useFeatures } from "../common/FeatureFlags";
import {
  CAPACITOR_GRAPHQL_ENDPOINT_URL,
  DEPLOY_ENV,
  GRAPHQL_ENDPOINT_URI,
  LAMBDA_GRAPHQL_ENDPOINT_URI,
} from "../environment";
import { mergeObjects } from "./apollo-client-merge";
import { relayStylePagination } from "./apollo-client-pagination";
import introspectionQueryResultData from "./fragmentTypes.json";
import persistedQueryIds from "./persisted-query-ids.client.json";

export function ApolloClientProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const { enqueueSnackbar } = useSnackbar();
  const [client, setClient] =
    React.useState<ApolloClient<NormalizedCacheObject> | null>(null);
  const { auth, isAuthenticated } = useAuth();
  const features = useFeatures();
  const latestFeatures = useLatest(features);
  const { selectedUserId } = useSelectedUser();

  React.useEffect(() => {
    (async () => {
      const uri = await graphqlEndpointUriByEnv();
      Sentry.configureScope(async scope => {
        scope.setTag("backend_url", uri);
      });
      if (Capacitor.isNativePlatform()) {
        Sentry.configureScope(async scope => {
          const appInfo = await App.getInfo();
          scope.setTag("app_version", appInfo.version);
          scope.setTag("app_build", appInfo.build);
        });
      }
      const { client } = createApolloClient(
        enqueueSnackbar,
        uri,
        isAuthenticated,
        auth,
        latestFeatures,
        selectedUserId
      );
      setClient(client);
    })();
  }, [enqueueSnackbar, auth, latestFeatures, selectedUserId, isAuthenticated]);

  if (!client) return null;

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

function graphqlEndpointUriByEnv() {
  if (DEPLOY_ENV === "local" && Capacitor.isNativePlatform()) {
    return CAPACITOR_GRAPHQL_ENDPOINT_URL;
  }

  if (window.sessionStorage.getItem("x-use-lambda-backend") === "true") {
    return LAMBDA_GRAPHQL_ENDPOINT_URI;
  }

  return GRAPHQL_ENDPOINT_URI;
}

const { link: networkStatusNotifierLink, useApolloNetworkStatus } =
  createNetworkStatusNotifier();
export { useApolloNetworkStatus };

function createApolloClient(
  toastFn: ProviderContext["enqueueSnackbar"],
  uri: string,
  isAuthenticated: boolean,
  auth: AuthContext["auth"],
  features: { readonly current: ReturnType<typeof useFeatures> },
  selectedUserId: string | null
) {
  const contextLink = setContext(async (_, { headers }) => {
    const { pathname } = window.location;

    const routePath = pathname
      .split("/")
      .map(pathPart => {
        const idPathPart = !!pathPart
          .split("")
          .find(ch => ch.match(/[0-9A-Z]+/));

        return idPathPart ? "-" : pathPart;
      })
      .join("/");

    let extraAccessHeaders: Record<string, string> = {};

    const key = "x-use-local-cache";
    extraAccessHeaders[key] =
      window.sessionStorage.getItem(key) === "true" ? "true" : "false";

    if (isAuthenticated) {
      try {
        const refreshed = await auth.updateToken(5);
        if (refreshed) {
          console.info("token refreshed");
        }
      } catch (error) {
        console.error("Failed to refresh the token:", error);
      }
    }

    return {
      headers: {
        ...headers,
        "x-route-path": routePath,
        "x-orig-path": pathname,
        ...(auth.token ? { authorization: `Bearer ${auth.token}` } : undefined),
        ...(selectedUserId
          ? { [SELECTED_USER_HTTP_HEADER]: selectedUserId }
          : undefined),
        ...extraAccessHeaders,
      },
    };
  });

  const errorLink = onError(
    ({ networkError, graphQLErrors, operation, response }) => {
      const context = operation.getContext();
      const correlationId = context.response.headers.get("x-correlation-id");

      if (graphQLErrors) {
        graphQLErrors.forEach(graphQLError => {
          console.error(`[GraphQL error]:`, graphQLError);

          Sentry.captureException(new GraphQLError(graphQLError.message), {
            fingerprint: [
              operation.operationName,
              graphQLError.path?.join("->") ?? "",
            ],
            tags: {
              gql_operation: operation.operationName,
              gql_error_name: graphQLError.name,
              gql_error_path: graphQLError.path?.join("->"),
              correlation_id: correlationId,
            },
            extra: { variables: operation.variables },
          });

          const skipError =
            context.noErrorNotification === true ||
            context.noErrorNotification?.(graphQLError.message) === true;

          if (!skipError) toastFn(graphQLError.message, { variant: "error" });
        });
      } else {
        console.error(
          `[Network error]: ${JSON.stringify({ networkError }, null, 2)}`
        );
        Sentry.withScope(scope => {
          scope.setTag("correlation_id", correlationId);
          Sentry.captureException(networkError);
        });
        toastFn(
          "Oops, something went wrong. Please check your connection and try again later.",
          {
            variant: "error",
          }
        );
      }
    }
  );

  const trackingLink = new ApolloLink((operation, forward) => {
    const [def0] = operation.query.definitions;
    const ctx = operation.getContext();

    if (def0.kind === "OperationDefinition" && !ctx.noTracking) {
      track({
        eventName: `graphql_${def0.operation}`,
        data: {
          operationName: operation.operationName,
          variables: operation.variables,
        },
      });
    }

    return forward(operation);
  });

  const persistedQueryLink = createPersistedQueryLink({
    generateHash: doc => {
      assert(
        doc.definitions[0].kind === "OperationDefinition",
        "not a OperationDefinition"
      );

      const operationName = doc.definitions[0].name?.value;

      assert(operationName, "no operationName");

      return (persistedQueryIds as any)[operationName];
    },
    // sha256,
    useGETForHashedQueries:
      window.sessionStorage.getItem("x-msys-graphql-get") === "true",
  });

  const customFetch = async (
    uri: RequestInfo | URL,
    options?: RequestInit | undefined
  ) => {
    const featureHeaders = Object.fromEntries(
      Object.entries(features.current)
        .filter(([key, val]) => val === true)
        .map(([key, val]) => [`x-feature-${key}`, String(val === true)])
    );

    options = {
      ...(options ?? {}),
      headers: { ...(options?.headers ?? {}), ...featureHeaders },
    };

    const res = await fetch(uri, options);

    if (res.headers.get("X-Force-Refetch-Apollo-Queries") === "true") {
      console.warn("received from server: X-Force-Refetch-Apollo-Queries");
      setTimeout(() => {
        client.reFetchObservableQueries();
      }, 100);
    }

    return res;
  };

  const client = new ApolloClient({
    connectToDevTools: true,
    link: ApolloLink.from([
      networkStatusNotifierLink,
      contextLink,
      errorLink,
      trackingLink,
      new MutationQueueLink(),
      new RetryLink({
        delay: {
          initial: 100,
          max: Infinity,
          jitter: true,
        },
        attempts: {
          max: 3,
          retryIf: (error, operation) => {
            const def = operation.query.definitions[0];

            if (
              def &&
              def.kind === "OperationDefinition" &&
              def.operation === "mutation"
            ) {
              // we do no retry failed mutations
              return false;
            }

            return !!error;
          },
        },
      }),
      persistedQueryLink,
      new HttpLink({
        uri,
        credentials: Capacitor.isNativePlatform() ? "omit" : "include",
        fetch: customFetch,
      }),
    ]),
    cache: new InMemoryCache({
      ...introspectionQueryResultData,
      typePolicies,
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
      query: {
        fetchPolicy: "network-only",
      },
    },
  });

  return {
    client,
  };
}

const typePolicies: TypePolicies = {
  Query: {
    fields: {
      requirement: {
        keyArgs: args =>
          args
            ? Object.keys(args).filter(
                (arg: string) => arg !== "expandedItemIds"
              )
            : undefined,
      },
      quote: {
        keyArgs: args =>
          args
            ? Object.keys(args).filter(
                (arg: string) => arg !== "expandedItemIds"
              )
            : undefined,
      },
      building: {
        keyArgs: args =>
          args
            ? Object.keys(args).filter(
                (arg: string) => arg !== "expandedItemIds"
              )
            : undefined,
      },
      quoteTemplateLatest: {
        keyArgs: args =>
          args
            ? Object.keys(args).filter(
                (arg: string) => arg !== "expandedItemIds"
              )
            : undefined,
      },
      quoteTemplateVersion: {
        keyArgs: args =>
          args
            ? Object.keys(args).filter(
                (arg: string) => arg !== "expandedItemIds"
              )
            : undefined,
      },
      wizardItem: {
        keyArgs: args =>
          args
            ? Object.keys(args).filter(
                (arg: string) => arg !== "applyItemActions"
              )
            : undefined,
      },
      pimSearchProducts: {
        keyArgs: args =>
          args ? Object.keys(args).filter(arg => arg !== "limit") : undefined,
      },
      projects: relayStylePagination((args, context) => {
        return args
          ? Object.keys(args).filter(
              arg =>
                arg !== "limit" &&
                arg !== "offset" &&
                arg !== "before" &&
                arg !== "after" &&
                arg !== "first" &&
                arg !== "last"
            )
          : undefined;
      }),
    },
  },
  Organisation: {
    fields: {},
  },
  OrganisationDefaults: {
    fields: {
      defaultMaterialMarginRanges: {
        merge(existing, incoming) {
          return mergeObjects(existing, incoming);
        },
      },
    },
  },
  OrganisationProfile: {
    fields: {
      shoppableTemplate: {
        keyArgs: args =>
          args
            ? Object.keys(args).filter(
                (arg: string) =>
                  arg !== "expandedItemIds" &&
                  arg !== "templateVariantConfiguration"
              )
            : undefined,
      },
      _3d_roomShoppableTemplate: {
        keyArgs: ["docId"],
      },
    },
  },
  Project: {
    fields: {
      billOfMaterialsItems: {
        keyArgs: args => args?.id || args?.quoteItemStatus || undefined,
      },
    },
  },
  QuoteTemplate: {
    keyFields: (object, context) => {
      if (object.resolvedAsReadModelVersionNumber === undefined) {
        const error = new Error(
          `Must include 'resolvedAsReadModelVersionNumber' in QuoteTemplate`
        );

        throw error;
      }

      if (object.resolvedAsReadModelVersionNumber === null) {
        return `${object.__typename}:${object.id}`;
      }

      return `${object.__typename}:${object.id}:Version:${object.resolvedAsReadModelVersionNumber}`;
    },
    fields: {
      items: {
        keyArgs: (args, context) => {
          return args ? Object.keys(args) : context.field?.alias?.value;
        },
        merge: (existing, incoming) => {
          return incoming;
        },
      },
    },
  },
  ShoppableTemplate: {
    fields: {
      items: {
        keyArgs: false,
        merge: (existing, incoming) => {
          return incoming;
        },
      },
    },
  },
  Requirement: {
    fields: {
      items: {
        merge: (existing, incoming) => {
          return incoming;
        },
      },
    },
  },
  Quote: {
    fields: {
      items: {
        merge: (existing, incoming) => {
          return incoming;
        },
      },
    },
  },
  TaskDocument: {
    fields: {
      items: {
        merge: (existing, incoming) => {
          return incoming;
        },
      },
    },
  },
  Building: {
    fields: {
      items: {
        merge: (existing, incoming) => {
          return incoming;
        },
      },
      buildingAddress: {
        merge(existing, incoming) {
          if (existing?.id === incoming.id) {
            return mergeObjects(existing, incoming);
          } else {
            return incoming;
          }
        },
      },
    },
  },
  Item: {
    keyFields: (object, context) => {
      if (object.originVersionNumber === undefined) {
        const error = new Error(`Must include 'originVersionNumber' in Item`);

        throw error;
      }

      if (object.originVersionNumber === null) {
        return `${object.__typename}:${object.id}`;
      }

      return `${object.__typename}:${object.id}:Version:${object.originVersionNumber}`;
    },
    fields: {
      templateSearchSortingDefinitions: {
        merge: (existing, incoming) => {
          return incoming;
        },
      },
      templateSearchSortingDefaults: {
        merge: (existing, incoming) => {
          return incoming;
        },
      },
      props2: {
        merge: (existing, incoming) => {
          return incoming;
        },
      },
      props2WithoutValue: {
        merge: (existing, incoming) => {
          return incoming;
        },
      },
    },
  },
  BillOfMaterialsItem: {
    keyFields: ["id", "quantityRequired", "quantityRequiredTotal"],
  },
  InvoiceItem: {
    fields: {
      itemSnapshot: {
        merge(existing, incoming) {
          return mergeObjects(existing, incoming);
        },
      },
    },
  },
  ItemSnapshot: {
    keyFields: false,
    merge(existing, incoming) {
      return mergeObjects(existing, incoming);
    },
  },
  EntityFieldMetadata: {
    keyFields: false,
    merge(existing, incoming) {
      return mergeObjects(existing, incoming);
    },
  },
  QuoteSapS4HanaCustomField: {
    keyFields: false,
    merge(existing, incoming) {
      return mergeObjects(existing, incoming);
    },
  },
  PimProductMetaInfo: {
    keyFields: false,
    merge(existing, incoming) {
      return mergeObjects(existing, incoming);
    },
  },
  ItemCalculation: {
    keyFields: false,
    merge(existing, incoming) {
      return mergeObjects(existing, incoming);
    },
  },
  ItemWizardSettings: {
    keyFields: false,
    merge(existing, incoming) {
      return mergeObjects(existing, incoming);
    },
  },
  ProposedItemCalculations: {
    fields: {
      selected: {
        merge(existing, incoming) {
          return mergeObjects(existing, incoming);
        },
      },
    },
  },
  ItemProductSearchFilterDefinitions: {
    keyFields: ["itemId"],
  },
  DefaultProductSearchFilters: {
    keyFields: ["itemId"],
  },
  ItemProductSearchFilterExpressions: {
    keyFields: ["itemId"],
  },
  Address: {
    keyFields: false,
    merge(existing, incoming) {
      return mergeObjects(existing, incoming);
    },
  },
  AddressSnapshot: {
    keyFields: false,
    merge(existing, incoming) {
      return mergeObjects(existing, incoming);
    },
  },
  DocActorContact: {
    keyFields: false,
    merge(existing, incoming) {
      return mergeObjects(existing, incoming);
    },
  },
  PdfLetterhead: {
    keyFields: false,
    merge(existing, incoming) {
      return mergeObjects(existing, incoming);
    },
  },
  TemplateTypeAtRevision: {
    keyFields: false,
    merge(existing, incoming) {
      return mergeObjects(existing, incoming);
    },
  },
  Channel: {
    keyFields: ["id"],
    fields: {
      messages: relayStylePagination((args, context) => {
        return args
          ? Object.keys(args).filter(
              arg =>
                arg !== "limit" &&
                arg !== "offset" &&
                arg !== "before" &&
                arg !== "after" &&
                arg !== "first" &&
                arg !== "last"
            )
          : undefined;
      }),
    },
  },
  Meister1Flow: {
    keyFields: ["id", "label", "identName"],
  },
  RecommendedTemplate: {
    keyFields: false,
    merge(existing, incoming) {
      return mergeObjects(existing, incoming);
    },
  },
  RecommendedTemplateType: {
    keyFields: false,
    merge(existing, incoming) {
      return mergeObjects(existing, incoming);
    },
  },
  ProjectRequirementsConnection: {
    keyFields: false,
    merge(existing, incoming) {
      return mergeObjects(existing, incoming);
    },
  },
  ProjectIncomingInvoiceConnection: {
    keyFields: false,
    merge(existing, incoming) {
      return mergeObjects(existing, incoming);
    },
  },
  ProjectOutgoingInvoiceConnection: {
    keyFields: false,
    merge(existing, incoming) {
      return mergeObjects(existing, incoming);
    },
  },
  ProjectIncomingQuoteConnection: {
    keyFields: false,
    merge(existing, incoming) {
      return mergeObjects(existing, incoming);
    },
  },
  ProjectOutgoingQuoteConnection: {
    keyFields: false,
    merge(existing, incoming) {
      return mergeObjects(existing, incoming);
    },
  },
  RequirementsConnection: {
    keyFields: false,
    merge(existing, incoming) {
      return mergeObjects(existing, incoming);
    },
  },
  IncomingInvoiceConnection: {
    keyFields: false,
    merge(existing, incoming) {
      return mergeObjects(existing, incoming);
    },
  },
  OutgoingInvoiceConnection: {
    keyFields: false,
    merge(existing, incoming) {
      return mergeObjects(existing, incoming);
    },
  },
  IncomingQuoteConnection: {
    keyFields: false,
    merge(existing, incoming) {
      return mergeObjects(existing, incoming);
    },
  },
  OutgoingQuoteConnection: {
    keyFields: false,
    merge(existing, incoming) {
      return mergeObjects(existing, incoming);
    },
  },
  TodoItemsConnection: {
    keyFields: false,
    merge(existing, incoming) {
      return mergeObjects(existing, incoming);
    },
  },
  OrderConnection: {
    keyFields: false,
    merge(existing, incoming) {
      return mergeObjects(existing, incoming);
    },
  },
};

class GraphQLError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "GraphQLError";
  }
}
